$gutters: 4 8 10 16 20 30 40 50 60 80 100;

.gutter {
  @each $gutter in $gutters {
    &.-all-#{$gutter} {
      margin: 0 -#{$gutter / 2}px;
      & > [class^="col"] {
        padding: 0 #{$gutter / 2}px;
      }
    }
  }

  @each $gutter in $gutters {
    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
        &.-over-#{$breakpoint}-#{$gutter} {
          margin: 0 -#{$gutter / 2}px;
          & > [class^="col"] {
            padding: 0 #{$gutter / 2}px;
          }
        }
      }

      @include media-breakpoint-down($breakpoint) {
        &.-under-#{$breakpoint}-#{$gutter} {
          margin: 0 -#{$gutter / 2}px;
          & > [class^="col"] {
            padding: 0 #{$gutter / 2}px;
          }
        }
      }
    }
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;

  &__main {
    width: 100%;
    padding: 0 12px;
    margin-bottom: 30px;
  }

  &__aside {
    width: 100%;
    padding: 0 12px;
  }

  @media (min-width: 992px) {
    &__main {
      order: 1;
      flex: 0 0 calc(100% - 300px);
      max-width: calc(100% - 300px);
      margin-bottom: 0;
    }

    &__aside {
      flex: 0 0 300px;
      max-width: 300px;
    }
  }
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  grid-auto-flow: row dense;

  &__col {
    &:nth-child(9n + 4) {
      grid-column: span 2;
      grid-row: span 2;
    }
  }

  @media (max-width: 575px) {
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
}
