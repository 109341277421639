.pagination {
  margin-bottom: 0;

  .page-item {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  .page-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    color: $primary;
    border: 0 !important;
    background: #e2e2e2 !important;
    border-radius: 9px !important;
    padding: 0;
    font-size: 13px;
    line-height: 22px;
  }

  .page-item.active .page-link {
    background-color: $primary !important;
  }

  @media (max-width: 767px) {
    .page-item {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
