.menu {
  list-style: none;
  margin: 0;
  padding: 0;

  &:not(.menu-sub) {
    border-bottom: 1px solid #aaa;
  }

  &-item {
    position: relative;
    border-top: 1px solid #aaa;
  }

  &-link {
    display: block;
    width: 100%;
    font-size: 1.8rem;
    line-height: 40px;
    padding: 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  &-toggle {
    display: inline-flex;
    width: 40px;
    height: 40px;
    border-left: 1px solid #aaa;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    &::before {
      content: "\f105";
      font-family: "fontawesome";
      display: flex;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
      color: #666;
      font-size: 1.8rem;
      border-radius: 40px;
      // background-color: rgba(#000, 0.1);
      transition: 0.3s ease-out;
    }

    &.active {
      // background-color: rgba(#15191e, 0.08);

      &::before {
        transform: rotate(90deg);
      }
    }
  }

  &-sub {
    display: none;
    // background-color: $gray-100;
    background-color: rgba(#15191e, 0.08);

    .menu-link {
      padding-left: 35px;
      position: relative;
    }
  }

  &-sub-2 {
    // background-color: $gray-200;

    .menu-link {
      padding-left: 45px;
    }
  }

  &-sub-3 {
    // background-color: $gray-300;

    .menu-link {
      padding-left: 55px;
    }
  }

  @media (min-width: #{$navbar-breakpoint}px) {
    &:not(.menu-sub) {
      border: 0;
    }

    &-item {
      border: 0;
    }

    // menu root
    &-root {
      display: flex;
      border: 0;
      margin-left: 60px;
    }

    &-root > &-item {
      &:not(:last-child) {
        margin-right: 54px;
      }
    }

    &-root > &-item > &-link {
      color: $white;
      padding: 0 !important;
      line-height: 100px;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 100%;
        border-top: 1px solid $white;
        position: absolute;
        bottom: 24px;
        left: 0;
        transform: rotateY(90deg);
        transition: 0.3s;
      }

      &.active {
        &::before {
          transform: rotateY(0);
        }
      }
    }

    &-root > &-item:hover > &-link {
      &::before {
        transform: rotateY(0);
      }
    }

    &-root > &-item > &-toggle {
      display: none;
    }

    &-root > &-item-group &-item > &-link {
      color: #7e7d7d;
    }

    &-root > &-item-group &-item:hover > &-link {
      color: $primary;
      text-shadow: 0 0 0.65px $primary, 0 0 0.65px $primary;
    }

    &-root > &-item-group &-sub {
      min-width: 250px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      border: 1px solid #ddd;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 100%;
      transform-origin: 0 4px;
      background-color: $white;
      padding: 16px 0;

      &-right {
        left: auto;
        right: calc(100% + 4px);
        transform-origin: 100% 0;

        &::before {
          right: auto;
          left: 100%;
        }
      }
    }

    &-root > &-item-group > &-sub {
      transform-origin: 0 0;
      top: 100%;
      left: -25px;
    }

    &-root > &-item-group &-sub &-toggle {
      width: 30px;
      border-left: 0;
    }

    &-root > &-item-group &-sub &-link {
      font-weight: 300;
      padding: 0 30px;
    }

    &-root > &-item-group &-item-group > &-link {
      padding-right: 30px;
    }

    &-root > &-item-group:hover > &-sub,
    &-root > &-item-group &-item-group:hover > &-sub {
      display: block;
      animation: menu-float-show 0.2s ease-out;
    }
  }
}
