.post {
  &-title {
    font-size: 3.4rem;
    font-weight: 500;
    color: $primary;
    margin-bottom: 8px;
  }

  &-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #999;
    margin-bottom: 16px;
  }

  &-time {
    font-size: 1.4rem;
    color: #7e7d7d;
    margin-right: 20px;
  }

  &-tag {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 28px;
    padding: 0 12px;
    border-radius: 14px;
    background-color: $primary;
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &-content {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.67;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }
}
