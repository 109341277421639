.share-btns {
  display: flex;
  align-items: center;

  &__label {
    font-size: 1.8rem;
    font-weight: 500;
    color: $primary;
    margin-right: 20px;
  }

  &__btn {
    &:not(:last-child) {
      margin-right: 16px;
    }

    .icon-facebook:hover {
      path {
        fill: $facebook;
      }
    }

    .icon-instagram:hover {
      path {
        fill: $instagram;
      }
    }
  }
}

.cat {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__body {
    border-radius: 28px;
    color: #ffe3ef;
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin-bottom: 60px;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 100%;
      background-color: currentColor;
      opacity: 0.5;
      position: relative;
      z-index: -3;
    }

    &::after {
      content: "";
      display: block;
      width: 140%;
      padding-bottom: 140%;
      border-radius: 50%;
      background-color: currentColor;
      position: absolute;
      bottom: 28%;
      left: 28%;
      z-index: -2;
    }
  }

  &__frame {
    display: block;
    width: 100%;
    height: 80%;
    position: absolute;
    top: 0;
    left: 0;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: contain;
      object-position: 50% 50%;
      width: 80%;
      height: 80%;
    }
  }

  &__name {
    text-align: center;
    width: 100%;
    height: 20%;
    font-size: 3.4rem;
    line-height: 1.2;
    color: $primary;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__link {
    @include frame("shine");
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  @media (max-width: 1199px) {
    &__body {
      margin-bottom: 30px;
    }

    &__name {
      font-size: 3rem;
    }
  }
}

.product {
  height: 100%;
  border: 1px solid #ccc;
  position: relative;
  z-index: 1;
  padding: 0 0 16px;
  transition: 0.3s;

  &:hover {
    border-color: $primary;
  }

  &__frame {
    @include frame("rect", 66.66%);

    img {
      transition: 0.4s ease-out;
    }
  }

  &:hover &__frame img {
    transform: scale(1.05);
  }

  &__name {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;
    color: $primary;
    padding: 8px 8px 0;
    margin: 0;
  }

  &__price {
    font-size: 1.8rem;
    text-align: center;
    color: #7e7d7d;
    padding: 0 10px;
  }

  &__favorite {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 35px;
    cursor: pointer;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 11;

    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }

    path {
      stroke: #7e7d7d;
    }

    &:hover {
      path {
        stroke: #7e7d7d;
        fill: #7e7d7d;
      }
    }

    input:checked ~ svg path {
      stroke: #ff9494;
      fill: #ff9494;
    }
  }
}

.collection {
  position: relative;
  z-index: 1;

  &__frame {
    @include frame("rect", 150%);
    @include frame("shine");

    img {
      transition: 0.4s ease-out;
    }
  }

  &:hover &__frame img {
    transform: scale(1.05);
  }

  &__btns {
    display: flex;
    position: absolute;
    z-index: 10;
    top: 16px;
    right: 16px;
  }

  &__favorite {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0 1px 6px rgba(#000, 0.15);
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 15px;
    }

    svg {
      circle,
      path {
        stroke: #444;
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0 1px 6px rgba(#000, 0.15);
    cursor: pointer;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-bottom: 0;

    &:not(:first-child) {
      margin-left: 14px;
    }

    circle,
    path {
      stroke: #444;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }

    &:hover {
      circle,
      path {
        stroke: $pink;
      }
    }

    input:checked ~ .icon-heart {
      path {
        fill: $pink;
        stroke: $pink;
      }
    }
  }

  &__dropdown {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    padding: 8px 2px 0;
  }

  &__dropdown &__btn {
    width: 26px;
    height: 26px;
    opacity: 0.6;

    &:not(:first-child) {
      margin-left: 0;
      margin-top: 8px;
    }

    svg {
      width: 18px;
      height: 18px;
    }

    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    &__btns {
      top: 4px;
      right: 4px;
    }

    &__btn {
      width: 26px;
      height: 26px;

      &:not(:first-child) {
        margin-left: 6px;
      }
    }

    &__dropdown {
      padding: 6px 0 0;
    }

    &__dropdown &__btn {
      &:not(:first-child) {
        margin-top: 6px;
      }
    }
  }
}

.news {
  box-shadow: 0px 4px 4px rgba(27, 34, 114, 0.15);

  &__frame {
    @include frame("rect", 66.66%);
    @include frame("zoom");
  }

  &__body {
    padding: 30px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__title {
    font-size: 2.4rem;
    line-height: 1.3;
    color: $primary;
    margin-bottom: 0;
  }

  &__time {
    font-size: 1.4rem;
    color: #7e7d7d;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  &__tag {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 28px;
    color: $primary;
    border: 1px solid $primary;
    border-radius: 15px;
    padding: 0 12px;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__desc {
    font-size: 1.8rem;
    line-height: var(--line-height);
    font-weight: 300;
    text-align: justify;
    margin-bottom: 16px;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  &__share {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    line-height: 24px;
    font-weight: 500;
    color: $primary;

    span {
      margin-right: 20px;
    }
  }

  @media (max-width: 575px) {
    &__body {
      padding: 24px;
    }

    &__header {
      display: block;
      margin-bottom: 8px;
    }

    &__time {
      margin-top: 8px;
    }

    &__desc {
      font-size: 1.6rem;
    }

    &__footer {
      display: block;
    }

    &__share {
      margin-top: 16px;
    }
  }
}

.news-2 {
  &__frame {
    @include frame("rect", 66.66%);
    width: 123px;
    margin-right: 10px;
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.3;
    color: $primary;
    margin-top: -2px;
    margin-bottom: 8px;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
  }

  &__tag {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 24px;
    color: $primary;
    border: 1px solid $primary;
    border-radius: 15px;
    padding: 0 12px;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__time {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: -1px;
  }
}

.news-3 {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0px 4px 4px rgba(27, 34, 114, 0.15);

  &__frame {
    @include frame("rect", 66.66%);
    @include frame("zoom");
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 16px 16px 20px;
  }

  &__title {
    font-size: 2.4rem;
    font-weight: 500;
    color: $primary;
    line-height: 1.3;
    text-align: center;
    margin-bottom: 8px;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  &__tag {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 28px;
    padding: 0 12px;
    border-radius: 15px;
    border: 1px solid $primary;
    margin-right: 12px;

    &:hover {
      text-decoration: none;
    }
  }

  &__time {
    font-size: 1.4rem;
    font-weight: 500;
    color: #7e7d7d;
  }

  &__desc {
    font-size: 1.8rem;
    line-height: var(--line-height);
    font-weight: 300;
    margin-bottom: 20px;
  }
}
