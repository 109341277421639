.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 100px);
  padding: 80px 0;

  &::before {
    content: "";
    display: block;
    width: 170%;
    padding-bottom: 170%;
    border-radius: 50%;
    background-color: #fffbe3;
    position: absolute;
    z-index: -1;
    left: -30%;
    bottom: 60px;
  }

  &__img {
    max-height: calc(100% - 100px);
    object-fit: contain;
    object-position: 0 100%;
    position: absolute;
    bottom: 50px;
    left: 0;
    z-index: -1;
  }

  &__inner {
    width: 80%;
    margin-left: auto;
  }

  &__title {
    font-size: 4.5rem;
    font-weight: 500;
    color: $primary;
    text-transform: uppercase;
    line-height: 1.3;
    margin-bottom: 16px;
  }

  &__subtitle {
    font-size: 2.4rem;
    color: $primary;
    margin-bottom: 24px;
  }

  &__address {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: var(--line-height);
    color: $primary;
    margin-top: 16px;
  }

  &__btn {
    line-height: 35px;
  }

  .form-control {
    border: 0;
    padding: 13px 16px;
    font-size: 1.6rem;
    font-weight: 300;

    &::placeholder {
      color: #7e7d7d;
    }
  }

  input.form-control {
    height: 50px;
  }

  @media (max-width: 1699px) {
    &__inner {
      width: 70%;
    }
  }

  @media (max-width: 1399px) {
    padding: 60px 0;

    &__title {
      font-size: 3.5rem;
    }

    &__subtitle {
      font-size: 2rem;
    }
  }

  @media (max-width: 1199px) {
    min-height: unset;
  }

  @media (max-width: 991px) {
    &::before {
      width: 200%;
      padding-bottom: 200%;
    }

    &__img {
      display: none;
    }

    &__inner {
      width: 100%;
    }
  }
}
