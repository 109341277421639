.button {
  display: inline-block;
  border: 1px solid $primary;
  border-radius: 4px;
  background: 0;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 24px;
  padding: 7px 20px;
  transition: 0.3s;
  min-width: 170px;
  text-align: center;
  color: $primary;

  &:hover {
    color: $white;
    background-color: $primary;
    text-decoration: none;
  }

  &--primary {
    background-color: $primary;
    color: $white;
  }

  &--block {
    width: 100%;
  }
}
