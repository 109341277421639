.lang {
  display: inline-flex;
  position: relative;

  &__toggle {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 35px;
    color: #fff;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }

  &__dropdown {
    display: none;
    @include shadow("");
    min-width: 160px;
    border-radius: 4px;
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    transform-origin: calc(100% - 40px) 0;
    background-color: #fff;

    &.show {
      display: block;
      animation: menu-float-show 0.2s;
    }

    &::before,
    &::after {
      content: "";
      border: 6px solid transparent;
      border-width: 6px 8px;
      border-bottom-color: $border-color;
      position: absolute;
      bottom: 100%;
      right: 40px;
      transform: translateX(50%);
    }

    &::after {
      border-bottom-color: $white;
      margin-bottom: -1px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    color: #333;

    &:hover {
      color: #333;
      text-decoration: none;
      background-color: $light;
    }

    &:not(:last-child) {
      border-bottom: 1px dotted $border-color;
    }

    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
    }

    img {
      height: 20px;
      margin-right: 16px;
      border: 1px solid $border-color;
      margin-left: -4px;
    }
  }
}
