@mixin slider-nav-1 {
  &__prev,
  &__next {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #000;
    background-color: #fff;
    font-size: 1.8rem;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 3%;
    transform: translateY(-50%);
    cursor: pointer;
    @include shadow("");
    transition: 0.3s ease-out;

    &:hover {
      @include shadow("");
      transform: translateY(-50%) scale(1.2);
    }

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: 3%;
  }

  &:hover &__prev,
  &:hover &__next {
    background-color: rgba(#fff, 1);
  }

  @media (min-width: 1200px) {
    &__prev {
      left: 0;
      opacity: 0;
    }

    &__next {
      right: 0;
      opacity: 0;
    }

    &:hover &__prev {
      left: 3%;
      opacity: 1;
    }

    &:hover &__next {
      right: 3%;
      opacity: 1;
    }
  }

  @media (max-width: 575px) {
    &__prev,
    &__next {
      background-color: rgba(#fff, 0.5);
    }
  }
}

@mixin slider-nav-2 {
  &__prev,
  &__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #ccc;
    z-index: 10;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    transition: 0.3s ease-out;

    &:hover {
      @include shadow("");
      transform: translate(-50%, -50%) scale(1.2);
    }

    &:focus {
      outline: 0;
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  &__next {
    left: 100%;
  }

  @media (min-width: 1200px) {
    &__prev,
    &__next {
      border: 0;
      box-shadow: unset;
      font-size: 28px;
      line-height: 30px;
      color: #777;
    }

    &__prev {
      left: -24px;
    }

    &__next {
      left: calc(100% + 24px);
    }
  }
}

// .brand-slider {
//   position: relative;
//   z-index: 1;

//   &__prev,
//   &__next {
//     padding: 10px 5px;
//     font-size: 20px;
//     color: $secondary;
//     font-size: 26px;
//     color: $primary;
//     position: absolute;
//     top: 50%;
//     left: -40px;
//     z-index: 10;
//     transform: translateY(-50%);
//     cursor: pointer;

//     &:focus {
//       outline: 0;
//     }
//   }

//   &__next {
//     left: auto;
//     right: -40px;
//   }

//   &__frame {
//     display: block;
//     position: relative;

//     &::before {
//       content: "";
//       display: block;
//       width: 100%;
//       padding-bottom: 60%;
//     }

//     img {
//       max-width: 100%;
//       max-height: 100%;
//       object-fit: contain;
//       object-position: 50% 50%;
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//     }
//   }

//   @media (max-width: 575px) {
//     &__prev,
//     &__next {
//       display: flex;
//       width: 30px;
//       height: 30px;
//       border-radius: 50%;
//       padding: 0;
//       justify-content: center;
//       align-items: center;
//       background-color: $white;
//       border: 1px solid $border-color;
//       font-size: 20px;
//       line-height: 1;
//     }

//     &__prev {
//       left: -15px;
//     }

//     &__next {
//       right: -15px;
//     }
//   }
// }

.brand-slider {
  position: relative;
  z-index: 1;

  @include slider-nav-2;

  &__frame {
    display: block;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 60%;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.product-slider {
  position: relative;
  z-index: 1;

  @include slider-nav-2;
}

.feedback-slider {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    background-color: #d1a02a;
    width: 100%;
    height: calc(100% - 100px);
    position: absolute;
    z-index: -2;
    top: 100px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__bg {
    width: 100%;
    height: calc(100% - 100px);
    opacity: 0.1;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    top: 100px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__prev,
  &__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    margin-top: 50px;
    cursor: pointer;

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__next {
    left: auto;
    right: 20px;
  }

  &__inner {
    padding: 70px 80px;
    padding-left: calc(35% + 80px);
    min-height: 420px;
    height: 100%;
    position: relative;
    z-index: 1;
  }

  &__img {
    height: calc(100% + 100px);
    position: absolute;
    bottom: 0;
    left: 80px;
    z-index: -1;
  }

  &__inner--right {
    padding-right: calc(35% + 80px);
    padding-left: 80px;
  }

  &__inner--right &__img {
    left: auto;
    right: 80px;
  }

  &__inner--right &__content {
    margin-left: auto;
    text-align: right;
  }

  &__content {
    max-width: 700px;
  }

  &__title {
    font-size: 3.4rem;
    color: $white;
    margin-bottom: 70px;
  }

  &__desc {
    font-size: 1.8rem;
    color: $white;
  }

  .swiper-slide {
    height: auto;
    padding-top: 100px;
  }

  @media (max-width: 1199px) {
    &__inner {
      padding: 60px 80px;
      padding-left: calc(34% + 80px);
      min-height: 350px;
    }

    &__title {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 767px) {
    &::before {
      top: 0;
      bottom: 0;
      height: 100%;
    }

    &__bg {
      top: 0;
      bottom: 0;
      height: 100%;
    }

    &__prev {
      top: auto;
      transform: unset;
      bottom: 10px;
      left: auto;
      right: 50%;
      padding-right: 10px;
    }

    &__next {
      top: auto;
      transform: unset;
      bottom: 10px;
      right: auto;
      left: 50%;
      padding-left: 10px;
    }

    &__inner {
      padding: 30px 20px 60px;
      min-height: unset;
    }

    &__title {
      font-size: 3rem;
      margin-bottom: 20px;
    }

    &__img {
      display: none;
    }

    .swiper-slide {
      padding-top: 0;
    }
  }
}

.banner-slider {
  position: relative;
  z-index: 1;

  @include slider-nav-1;

  &__frame {
    img {
      display: block;
      width: 100%;
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 16px;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      opacity: 1;
      background-color: #b5b4b4;

      &:not(:last-child) {
        margin-right: 12px;
      }

      &-active {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: 0;
        border: 1px solid $primary;
        position: relative;

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 2px;
          left: 2px;
          right: 2px;
          bottom: 2px;
          border-radius: 50%;
          background-color: $primary;
        }
      }
    }
  }
}

.cat-slider {
  position: relative;
  z-index: 1;

  @include slider-nav-2;
}
