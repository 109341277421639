.footer {
  background-color: #1b2272;
  padding: 40px 0 0;

  &__grid {
    display: grid;
    grid-template-columns: 1fr 150px 350px 320px;
    grid-gap: 30px;
  }

  &__content {
    font-size: 1.8rem;
    line-height: 1.8;

    &,
    a,
    a:hover {
      color: $white;
    }
  }

  &__contact {
    line-height: 1.8;

    &,
    a,
    a:hover {
      color: $white;
    }
  }

  &__copyright {
    font-weight: 300;
    border-top: 1px solid $white;
    text-align: center;
    color: $white;
    padding: 9px 0;
    margin-top: 30px;
  }

  @media (max-width: 1199px) {
    &__grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 767px) {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}

.f-menu {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  line-height: 1.8;

  a {
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

.f-social {
  display: flex;

  a {
    display: flex;

    &:hover {
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 12px;
    }

    img {
      height: 24px;
    }
  }
}

.f-form {
  &__title {
    color: $white;
    font-size: 3rem;
    line-height: 1.2;
    margin-bottom: 4px;
  }

  &__desc {
    color: $white;
    line-height: 1.8;
    margin-bottom: 12px;
  }

  &__btn {
    display: block;
    width: 100%;
    text-align: center;
    background: $white;
    border: 0;
    border-radius: 4px;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 35px;
    padding: 0 10px;
    color: $primary;

    &:hover {
      color: $primary;
      background-color: $light;
      text-decoration: none;
    }
  }

  .form-control {
    font-weight: 300;
    height: 35px;
    border: 0;
    padding-left: 16px;
    font-size: 1.6rem;
  }
}
