.section {
  padding: 60px 0;

  &__title {
    font-size: 3.4rem;
    text-align: center;
    color: $primary;
    margin-bottom: 40px;

    span {
      display: inline-block;
      border-bottom: 1px solid $primary;
    }
  }

  &__desc {
    font-size: 1.8rem;
    line-height: var(--line-height);
    font-weight: 300;
    text-align: center;
    color: $primary;
    margin-bottom: 40px;
  }

  &__content {
    font-size: 1.8rem;
    line-height: var(--line-height);
    font-weight: 300;
    color: $primary;
  }

  @media (max-width: 1199px) {
    padding: 50px 0;

    &__title {
      font-size: 3rem;
      margin-bottom: 30px;
    }

    &__desc {
      font-size: 1.6rem;
    }
  }
}
