.feedback {
  padding: 60px 0;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    width: 160%;
    padding-bottom: 160%;
    border-radius: 50%;
    background-color: #ffedcf;
    position: absolute;
    z-index: -1;
    top: 50px;
    left: -20%;
  }

  &__title {
    font-size: 5.6rem;
    font-weight: 500;
    line-height: 1.3;
    color: $primary;
    margin-bottom: 30px;
  }

  &__btn {
    line-height: 35px;
  }

  .form-control {
    border: 0;
    padding: 13px 16px;
    font-size: 1.6rem;
    font-weight: 300;

    &::placeholder {
      color: #7e7d7d;
    }
  }

  input.form-control {
    height: 50px;
  }

  @media (max-width: 1199px) {
    &__title {
      font-size: 5rem;
    }
  }

  @media (max-width: 991px) {
    padding: 40px 0;

    &::before {
      top: 15px;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 4rem;
    }

    .grid__main {
      margin-bottom: 0;
    }
  }

  @media (max-width: 575px) {
    &::before {
      width: 200%;
      left: -40%;
    }

    &__title {
      font-size: 3rem;
    }
  }
}
