.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 267px;
  position: relative;
  z-index: 1;

  &__bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 75% 50%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
  }

  &__title {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
    color: $primary;

    span {
      display: inline-block;
      padding-bottom: 8px;
      border-bottom: 1px solid $primary;
    }
  }

  @media (max-width: 1399px) {
    &__title {
      font-size: 5rem;
    }
  }

  @media (max-width: 767px) {
    height: 180px;

    &__title {
      font-size: 3.5rem;
    }
  }
}
