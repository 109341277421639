.logo {
  display: inline-flex;
  margin: 10px 0;

  img {
    height: 80px;
  }
}

.header {
  position: relative;
  z-index: 100;
  background-color: $primary;

  &__inner {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__elements {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__btn {
    display: inline-flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    position: relative;
    color: $gray-800;
    font-size: 1.8rem;

    & + & {
      margin-left: 4px;
    }

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    span {
      display: inline-flex;
      width: 15px;
      height: 15px;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      border-radius: 50%;
      font-size: 11px;
      font-weight: 700;
      text-align: center;
      color: $white;
      position: absolute;
      top: 0;
      right: 0;
    }

    img {
      max-height: 16px;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  @media (min-width: 1200px) {
    &__btn {
      display: none;
    }
  }
}

.h-hotline {
  display: flex;
  align-items: center;
  color: $white;
  font-size: 1.8rem;
  font-weight: 300;

  &:hover {
    color: $white;
    text-decoration: none;
  }

  img {
    height: 20px;
  }

  span {
    display: none;
  }

  @media (min-width: 768px) {
    span {
      display: inline;
    }
  }
}

.wishlist-btn {
  display: inline-flex;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  padding-top: 2px;

  &__img-hover {
    display: none;
  }

  img {
    height: 20px;
  }

  &:hover &__img {
    display: none;
  }

  &:hover &__img-hover {
    display: inline;
  }
}
