.h-intro {
  margin: 0 auto;
  max-width: 1010px;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
    height: 125px;
    border: 1px solid $primary;
    padding: 16px;
    border-radius: 50%;
    margin: 0 auto;

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__text {
    font-size: 2.4rem;
    color: $primary;
    line-height: 1.3;
    text-align: center;
    margin-top: 20px;
  }
}

.payment-methods {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;

  &__frame {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__label {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;
    color: $primary;
    margin-top: 20px;
  }

  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 575px) {
    grid-gap: 24px 16px;

    &__frame {
      height: 50px;
    }

    &__label {
      font-size: 1.4rem;
    }
  }
}

.booking {
  padding: 120px 0;
  margin-top: 100px;
  background-color: #d1a02a;
  position: relative;
  z-index: 1;

  &__bg {
    @include img("bg");
    object-fit: cover;
    object-position: 50% 50%;
    opacity: 0.05;
  }

  &__img {
    position: absolute;
    right: 20px;
    bottom: 0;
    max-height: calc(100% + 100px);
    object-fit: contain;
    object-position: 100% 100%;
    z-index: -1;
  }

  &__title {
    font-size: 5.6rem;
    font-weight: 500;
    line-height: 1.4;
    color: $white;
    text-transform: uppercase;
    margin-bottom: 100px;
  }

  &__btn {
    font-size: 2.4rem;
    text-transform: uppercase;
    background: $white;
    color: #d1a02a;
    border: 0;
    padding: 15px 80px;
    transition: 0.3s;

    &:hover {
      text-decoration: none;
      background-color: rgba(#fff, 0.85);
      color: #d1a02a;
    }
  }

  @media (max-width: 1399px) {
    padding: 100px 0;

    &__title {
      font-size: 5.6rem;
      margin-bottom: 80px;
    }

    &__btn {
      font-size: 2rem;
    }
  }

  @media (max-width: 991px) {
    padding: 70px 0;

    &__title {
      font-size: 4rem;
      margin-bottom: 40px;
    }

    &__btn {
      font-size: 1.6rem;
      padding: 12px 60px;
    }
  }

  @media (max-width: 575px) {
    padding: 30px 0 80px;
    margin-top: 0;

    &__title {
      font-size: 2.8rem;
      margin-bottom: 30px;
    }

    &__btn {
      font-size: 1.6rem;
      padding: 9px 30px;
    }

    &__img {
      max-height: 95%;
      right: 0;
    }
  }
}
