.breadcrumb {
  background: 0;
  padding: 20px 0;

  &-item {
    font-size: 1.4rem;

    &,
    & + &::before,
    &.active::before,
    a,
    a:hover {
      font-weight: 300;
      color: $primary;
    }

    &.active {
      font-weight: 500;
      color: $primary;
    }

    & + &::before {
      content: "\f105";
      font-family: fontawesome;
    }
  }
}
