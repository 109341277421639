.preview {
  &__thumbs {
    margin-top: 10px;
  }

  @media (min-width: 1200px) {
    &__thumbs {
      margin-top: 16px;
    }
  }
}

.preview-slider {
  border: 1px solid #7e7d7d;
  position: relative;
  z-index: 1;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0 1px 6px rgba(#000, 0.15);
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 20;
  }

  &__frame {
    @include frame("rect", 100%);
    background-color: $white;

    &--collection::before {
      padding-bottom: 150%;
    }
  }
}

.thumb-slider {
  @include slider-nav-2;
  position: relative;
  margin: 0 auto;
  max-width: 400px;

  &__frame {
    @include frame("rect", 100%);
    border: 1px solid #7e7d7d;
    cursor: pointer;

    &--collection::before {
      padding-bottom: 150%;
    }
  }
}
