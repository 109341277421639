.filter {
  &__group {
    margin-bottom: 20px;
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 500;
    color: $primary;
    margin-bottom: 16px;
  }
}

.product-desc {
  text-align: center;
  font-size: 1.8rem;
  font-style: italic;
  color: $primary;
}

.wishlist-aside {
  &__title {
    font-size: 3.4rem;
    font-weight: 500;
    color: $primary;
    margin-bottom: 24px;
  }

  &__btns {
    display: flex;
    flex-wrap: wrap;
  }

  &__btn {
    font-size: 1.4rem;
    line-height: 30px;
    border: 0;
    padding: 0 16px;
    border-radius: 15px;
    background-color: $light;
    color: $primary;
    margin-right: 16px;
    margin-bottom: 10px;

    &:hover {
      color: $primary;
      background-color: #ccc;
      text-decoration: none;
    }

    &.active {
      color: $white;
      background-color: $primary;
    }
  }

  @media (max-width: 1199px) {
    &__title {
      font-size: 3rem;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
}

.pd-info {
  &__title {
    font-size: 2.4rem;
    font-weight: 500;
    color: $primary;
    border-bottom: 1px solid $primary;
    padding-bottom: 6px;
    margin-bottom: 24px;
  }

  &__group {
    margin-bottom: 30px;
  }

  &__label {
    font-size: 2.4rem;
    font-weight: 500;
    color: $primary;
    padding-bottom: 4px;
    margin-bottom: 20px;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 40px;
      border-bottom: 1px solid $primary;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &__content {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.667;
    color: $primary;
  }

  &__btn {
    display: block;
    text-align: center;
    font-size: 1.8rem;
    line-height: 40px;
    color: $white;
    background-color: #d1a02a;
    border-radius: 4px;

    &:hover {
      background-color: darken(#d1a02a, 5%);
      color: $white;
      text-decoration: none;
    }
  }

  &__value {
    font-size: 1.8rem;
    color: $primary;
  }

  &__group--inline {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__group--inline &__label {
    min-width: 150px;
    margin-bottom: 0;
  }
}

.dimension {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 300;

  &__frame {
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__label {
    font-weight: 500;
    color: $primary;
  }
}
